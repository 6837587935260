<template>
  <div class="prize-box">
    <div class="empty-page" v-if="emptyPage">
      <img src="~images/dow-ceremony/prizes.png" alt="">
      <p>您暂时没有奖品</p>
    </div>
    <div class="prize">
      <ul>
        <li v-for="(prize, index) in prizeList.page" :key="index" @click="gotoGiftDetail(index)">
          {{prize.name}}
          <p>{{prize.createTime}}</p>
          <div class="prize-status">
            <span class="prize-btn-two" v-if="prize.status === 0">
              未兑奖
            </span>
            <span class="prize-btn-three" v-if="prize.status === 1">
              兑奖中
            </span>
            <span class="prize-btn" v-if="prize.status === 2">
              已兑奖
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { drawRecordPage } from 'api/draw'
import { getSessionStorage } from 'utils/store'
export default {
  data () {
    return {
      prizeList: {
        page: []
      },
      emptyPage: false
    }
  },
  mounted () {
    this.getGiftList()
  },
  methods: {
    getGiftList () {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      let userId = getSessionStorage('userId')
      let params = {userId: userId}
      let pagestr = `?pageNum=${1}&pageSize=${100}`
      drawRecordPage(JSON.stringify(params), pagestr).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          this.emptyPage = true
          return
        }
        this.prizeList = res.data.data || {}
        if (this.prizeList.page.length < 1 || this.prizeList === {}) {
          this.emptyPage = true
        } else {
          this.emptyPage = false
        }
      })
    },
    gotoGiftDetail (index) {
      let prize = {}
      prize.id = this.prizeList.page[index].id
      this.$router.push({ name: '奖品兑换', params: prize })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.prize-box {
  width: px2rem(750px);
  background: #f0f0f0;
  height: 100vh;
}

.prize {
  width: px2rem(720px);
  background: #fff;
  padding: 0 0 0 px2rem(30px);
  display: inline-block;
  ul {
    width: px2rem(720px);
    li {
      position: relative;
      width: 100%;
      margin: px2rem(10px) 0;
      padding: px2rem(22px) 0;
      font-size: px2rem(32px);
      color: $color-text;
      font-size: px2rem(32px);
      border-bottom: 1px solid $color-line;
      box-sizing: border-box;
      display: inline-block;
      p {
        margin: px2rem(10px) 0 0 0;
        color: #999;
        font-size: px2rem(24px);
      }
      .prize-status {
        position: absolute;
        right: px2rem(20px);
        bottom: px2rem(20px);
        display: inline-block;
        font-size: px2rem(28px);
        .prize-btn-two {
          width: px2rem(130px);
          height: px2rem(60px);
          background: $color-highlight;
          text-align: center;
          line-height: px2rem(60px);
          color: #fff;
          border-radius: 30px;
          display: inline-block;
        }
        .prize-btn {
          display: inline-block;
          width: px2rem(130px);
          height: px2rem(60px);
          color: #fff;
          background: #ccc;
          text-align: center;
          line-height: px2rem(60px);
          border-radius: 30px;
        }
        .prize-btn-three {
          width: px2rem(130px);
          height: px2rem(60px);
          background: #ff9da5;
          text-align: center;
          line-height: px2rem(60px);
          color: #fff;
          border-radius: 30px;
          display: inline-block;
        }
      }
    }
    li:last-child {
      border-bottom: none;
    }
  }
}
.empty-page {
  width: 100%;
  background: #f0f0f0;
  text-align: center;
  padding: px2rem(230px) auto 0;
  img {
    width: px2rem(156px);
    height: px2rem(158px);
    margin: px2rem(230px) auto 0;
  }
  p {
    line-height: px2rem(90px);
    font-size: px2rem(28px);
    color:#999;
  }
}
</style>
